import React from "react";
import { graphql, Link } from "gatsby";

import Rte from "../components/atoms/rte";
import Layout from "../components/layout";
import Seo from "../components/atoms/seo";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import SectionFold from "../components/organisms/section-fold";
import SectionForm from "../components/organisms/section-form";

const CareerTemplate = (props) => {
  const generalData = props.data?.generalData;
  const dictionary = props.data?.dictionary?.edges[0]?.node;
  const pagesData = props.data?.pagesData?.edges;
  const pageData = props.data?.pageData?.edges[0]?.node;

  return (
    <Layout>
      <Seo title={pageData.title} />
      <Header generalData={generalData} pagesData={pagesData} />
      <main>
        <SectionFold
          pageData={{
            ...pageData,
            pageTitle: pageData.title,
            pageHeaderImage: pageData.image,
          }}
          bgColor="bg-light"
          showScrollTo={true}
        />

        <section>
          <div className="container-fluid">
            <div className="row my-5 pb-5 position-relative">
              <div className="col-12 col-xl-10 col-xxl-10 mx-auto my-5">
                <Rte content={pageData.pageBody} />
              </div>
            </div>
          </div>
        </section>

        <SectionForm
          generalData={generalData}
          dictionary={dictionary}
          hideMap={true}
        />
      </main>
      <Footer generalData={generalData} pagesData={pagesData} />
    </Layout>
  );
};

export const query = graphql`
  query opportunityPage($id: String) {
    generalData: contentfulGeneralSettings {
      footerEmail
      footerPhoneNumber
      logo {
        fluid(maxWidth: 284, maxHeight: 160) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      contactLocation {
        lat
        lon
      }
      contactMarker {
        raw
      }
    }
    dictionary: allContentfulDictionary {
      edges {
        node {
          contactDisclaimer {
            raw
          }
          contactEmailLabel
          contactErrorMessage {
            raw
          }
          contactMessageLabel
          contactNameLabel
          contactSubmit
          contactSuccessMessage {
            raw
          }
        }
      }
    }
    pagesData: allContentfulPage {
      edges {
        node {
          slug
          pageName
          id
          hideInNavigation
          hideInFooter
          navigationOrder
        }
      }
    }
    pageData: allContentfulCareerOpportunities(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          image {
            fluid(maxWidth: 1000, maxHeight: 1000, quality: 80) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          slug
          pageBody {
            raw
          }
        }
      }
    }
  }
`;

export default CareerTemplate;
